import React from 'react';
import { Box, Link } from '@chakra-ui/react';
import styled from 'styled-components';

const GridBox = styled(Box)`
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: start;
  column-gap: 6rem;
  margin-bottom: 0rem;

  @media only screen and (max-width: 790px) {
    column-gap: 2.5rem;
    row-gap: 3.5rem;
    width: 100%;
  }

  @media only screen and (max-width: 499px) {
    row-gap: 3.5rem;
    grid-template-columns: auto auto;
    width: 100%;
  }
`;

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1rem;
`;

const Title = styled.p`
  color: #d7d7d7;
  font-size: 0.85rem;
  font-weight: bold;
  font-family: Inter;
`;

const TextLink = styled(Link)`
  color: #bdbdbd !important;
  font-size: 0.75rem !important;
  font-family: Inter !important;

  &:focus {
    outline: none;
  }
`;

const GridLink = () => {
  return (
    <GridBox>
      <FlexBox>
        <Title>Product</Title>
        <TextLink href="https://app.slashproof.org" _focus={{ outline: 'none' }} isExternal>Staking Protection</TextLink>
        <TextLink _focus={{ outline: 'none' }}>Restaking Protection (Coming soon!)</TextLink>
      </FlexBox>
      {/* <FlexBox>
        <Title>Developers</Title>
        <TextLink href="" _focus={{ outline: 'none' }} isExternal>Documentation</TextLink>
        <TextLink href="" _focus={{ outline: 'none' }} isExternal>Bug Bounty</TextLink>
      </FlexBox> */}
      <FlexBox>
        <Title>Community</Title>
        <TextLink href="https://twitter.com/SlashProofOrg" _focus={{ outline: 'none' }} isExternal>Twitter</TextLink>
        <TextLink href="https://discord.gg/WjDByjYDpW" _focus={{ outline: 'none' }} isExternal>Discord</TextLink>
      </FlexBox>
      <FlexBox>
        <Title>Company</Title>
        <TextLink href="https://medium.com/@SlashProof" _focus={{ outline: 'none' }} isExternal>Blog</TextLink>
        <TextLink href="https://discord.gg/WjDByjYDpW" _focus={{ outline: 'none' }} isExterna>Contact</TextLink>
      </FlexBox>
    </GridBox>
  );
}

export default GridLink;