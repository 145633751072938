import React from 'react';
import { Flex, Stack } from '@chakra-ui/react';
import { Logo, LogoText, DesktopContainer, MobileContainer } from './styles';
import logo from '../../assets/logo.svg';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

const Header = (props) => {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      marginTop="1rem"
      mb="5rem"
      bg={["primary.500", "primary.500", "transparent", "transparent"]}
      color={["white", "white", "white", "white"]}
      {...props}
    >
      <Stack spacing="0.75rem" direction="row">
        <Logo src={logo} />
        <LogoText>SlashProof</LogoText>
      </Stack>
      <DesktopContainer>
        <DesktopMenu />
      </DesktopContainer>
      <MobileContainer>
        <MobileMenu />
      </MobileContainer>
    </Flex>
  )
}

export default Header;
