import { Heading } from '@chakra-ui/react';
import styled from 'styled-components';

export const Logo = styled.img`
  width: 1.15rem;
`;

export const LogoText = styled.h1`
  color: #FAFAFA;
  font-family: Alien Robot;
  font-weight: 600;
  font-size: 1.35rem;
`;

export const Text = styled.p`
  color: var(--text-secondary-gray);
  font-size: 0.75rem;
  font-weight: 600;
  z-index: 999;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media only screen and (max-width: 960px) {
    font-size: var(--p-font-size);
  }
`;

export const DesktopContainer = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const MobileContainer = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    z-index: 999;
  }
`;

export const StyledHeading = styled(Heading)`
  font-size: 0.85rem;
  background: linear-gradient(180deg, #DFECFF 0%, #90BCFF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .1rem;
`;
