import React from 'react';
import { 
    Box,
    Button,
    Stack,
    useDisclosure,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    DrawerCloseButton,
    DrawerBody,
    Text,
    Link,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { StyledHeading } from './styles';

const MenuItems = (props) => {
  const {children, isLast, to = "/", isExternal, onClick, ...rest} = props
  return (
    <Text
      mb={{base: isLast ? 0 : 0, sm: 0}}
      mr={{base: 0, sm: isLast ? 0 : 8}}
      display="block"
      fontSize="0.8rem"
      {...rest}
    >
      {isExternal 
      ? (
          <Link
            href={to}
            isExternal
            _focus={{outline: 'none'}}
            onClick={onClick}
            _hover={{textDecoration: 'underline'}}
          >
            {children}
          </Link>
       ) : (
        <Link href={to} _focus={{outline: 'none'}} onClick={onClick} style={{textDecoration: 'none'}}>{children}</Link>
      )}
    </Text>
  );
}

const MobileMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = () => {
    window.open("https://app.slashproof.org", "_new");
    onClose();
  }

  return (
    <>
      <Box as="button" onClick={onOpen}>
        <HamburgerIcon w={6} h={6} color="white" onClick={onOpen} />
      </Box>
      <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="xs">
        <DrawerOverlay />
        <DrawerContent bg="gray.800">
        <DrawerCloseButton style={{color: 'white' }} _focus={{ outline: 'none' }} _hover={{ filter: 'brightness(65%)' }} />
          <DrawerBody>
            <Stack spacing="0.7rem" direction="column" mt="5">
              <StyledHeading as="h4" style={{marginTop: '30px'}}>Product</StyledHeading>
              <MenuItems textColor="white" pb="5px" to="https://app.slashproof.org" isExternal onClick={() => onClose()}>
                Staking Protection
              </MenuItems>
              <MenuItems textColor="white" pb="5px" onClick={() => onClose()}>
                Restaking Protection (Coming soon!)
              </MenuItems>

              {/* <StyledHeading as="h4" style={{marginTop: '30px'}}>Developers</StyledHeading>
              <MenuItems textColor="white" pb="5px" to="" isExternal onClick={() => onClose()} _hover={{ textDecoration: 'underline' }}>Documentation</MenuItems> */}

              <StyledHeading as="h4" style={{marginTop: '30px'}}>Community</StyledHeading>
              <MenuItems textColor="white" pb="5px" to="https://twitter.com/SlashProofOrg" isExternal onClick={() => onClose()} _hover={{ textDecoration: 'underline' }}>Twitter</MenuItems>
              <MenuItems textColor="white" pb="5px" to="https://discord.gg/WjDByjYDpW" isExternal onClick={() => onClose()} _hover={{ textDecoration: 'underline' }}>Discord</MenuItems>

              <StyledHeading as="h4" style={{marginTop: '30px'}}>Company</StyledHeading>
              <MenuItems as="a" textColor="white" pb="5px" to="https://medium.com/@SlashProof" isExternal onClick={() => onClose()} _hover={{ textDecoration: 'underline' }}>Blog</MenuItems>
              <MenuItems as="a" textColor="white" pb="25px" to="https://discord.gg/WjDByjYDpW" isExternal onClick={() => onClose()} _hover={{ textDecoration: 'underline' }}>Contact</MenuItems>
              <Button
                className="header-app-button"
                borderRadius="5px"
                fontSize="var(--p-font-size)"
                textColor="var(--text-primary-white)"
                fontFamily="Alien Encounters Regular"
                background="var(--components-primary-blue)"
                _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
                _focus={{ outline: 'none', boxShadow: 'none' }}
                width="135px"
                paddingTop="0.35rem"
                onClick={handleClick}
              >
                Enter App
              </Button>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MobileMenu;
