import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: var(--section-bm);
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  width: 49rem;

  @media only screen and (max-width: 834px) {
    width: 40rem;
  }

  @media only screen and (max-width: 689px) {
    width: 29rem;
  }

  @media only screen and (max-width: 499px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
  }
`;

export const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
`;

export const Image = styled.img`
  width: 20rem;
  height: 20rem;

  @media only screen and (max-width: 834px) {
    width: 16rem;
    height: 16rem;
  }

  @media only screen and (max-width: 689px) {
    width: 11.5rem;
    height: 11.5rem;
  }

  @media only screen and (max-width: 499px) {
    width: 16rem;
    height: 16rem;
  }
`;
