import './App.css';
import { ThemeProvider, CSSReset, theme } from '@chakra-ui/react';
import Header from './components/1_Header/Header';
import Intro from './components/2_Intro/Intro';
import Features from './components/3_Features/Features';
import Partners from './components/4_Partners/Partners';
import Footer from './components/5_Footer/Footer';
import heroBlur from './assets/rain.svg';
import styled from 'styled-components';

const Main = styled.div`
`;

const Container = styled.div`
`;

const HeroRain = styled.img`
  position: absolute;
  width: 100vw;
  top: 8%;
  filter: brightness(80%);
`;


function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CSSReset />
        <Main className="main">
          <HeroRain src={heroBlur} alt="hero-blur" />
          <Container className="container">
            <Header />
            <Intro />
            <Features />
            <Partners />
            <Footer />
          </Container>
        </Main>
      </ThemeProvider>
    </>
  );
}

export default App;
