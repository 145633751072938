import React from 'react';
import rewards from '../../assets/features/rewards.svg';
import restaking from '../../assets/features/restaking.svg';
import security from '../../assets/features/security.svg';
import { Container, FlexBox, Image } from './styles';

const Features = () => {
  return (
    <Container>
      <FlexBox>
        <Image src={rewards} alt="rewards" />
        <Image src={restaking} alt="restaking" />
        <Image src={security} alt="security" />
      </FlexBox>
    </Container>
  );
}

export default Features;
