import React from 'react';
import { Button, Link } from '@chakra-ui/react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4.5rem;
`;

const HeadingTitle = styled.h1`
  font-size: calc(1.5 * var(--h1-font-size));
  width: 33rem;
  text-align: center;
  text-shadow: 0px 10px 50px rgba(74, 179, 255, 0.40);
  font-family: Alien Encounters Regular;
  background: linear-gradient(180deg, #DFECFF 0%, #90BCFF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: var(--grid-item-bm);
  line-height: var(--header-line-height);
  font-weight: 500;

  @media only screen and (max-width: 576px) {
    width: 20rem;
  }
`;

const SubHeading = styled.p`
  font-size: var(--p-font-size);
  font-weight: 500;
  width: 28rem;
  text-align: center;
  text-shadow: 0px 10px 50px rgba(74, 179, 255, 0.40);
  background: linear-gradient(180deg, #DFECFF 0%, #90BCFF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Inter;
  overflow-wrap: break-word;

  @media only screen and (max-width: 418px) {
    width: 100%;
  }
`;


const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 2rem;
`;

const SubButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  column-gap: 0.5rem;
`;

const Intro = () => {
  return (
    <Container zIndex="999">
      <HeadingTitle>ZK STAKING PROTECTION FOR CRYPTO</HeadingTitle>
      <SubHeading>The easiest and safest way to protect staked and restaked assets. Backed by zero-knowledge proofs.</SubHeading>
      <ButtonContainer>
        <SubButtonContainer>
          <Button
            as={Link}
            _hover={{textDecoration: 'none', filter: 'brightness(85%)'}}
            _active={{color: 'transparent'}}
            _focus={{outline: 'none', boxShadow: 'none'}}
            textColor="#dfecff"
            fontSize="var(--p-font-size)"
            fontFamily="Alien Encounters Solid"
            paddingTop="0.25rem"
            // border="0.1px solid #FFF"
            borderRadius="3px"
            background="#0F265C"
            width="9rem"
            href="https://medium.com/@SlashProof/slashproof-testnet-live-on-karak-c0e952c6a31d"
            target="_new"
          >
            Learn More
          </Button>
          <Button
            as={Link}
            _hover={{textDecoration: 'none', filter: 'brightness(85%)'}}
            _active={{color: 'transparent'}}
            _focus={{outline: 'none', boxShadow: 'none'}}
            textColor="#FAFAFA"
            fontSize="var(--p-font-size)"
            fontFamily="Alien Encounters Solid"
            paddingTop="0.25rem"
            borderRadius="3px"
            background="var(--components-primary-blue)"
            width="9rem"
            href="https://app.slashproof.org"
            target="_new"
          >
            ENTER APP
          </Button>
        </SubButtonContainer>
      </ButtonContainer>
    </Container>
  );
}

export default Intro;
