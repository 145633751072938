import React from 'react';
import { Stack, Button, Link, useDisclosure, Menu, MenuList, MenuButton, Text } from '@chakra-ui/react';
import styled from 'styled-components';

const SMenuButton = styled(MenuButton)`
  font-size: 0.8rem;
  font-weight: 600;
  background: linear-gradient(180deg, #DFECFF 0%, #90BCFF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const MenuItems = (props) => {
  const {children, isLast, to = "/", isExternal, onClick, ...rest} = props
  return (
    <Text
      mb={{base: isLast ? 0 : 0, sm: 0}}
      mr={{base: 0, sm: isLast ? 0 : 8}}
      display="block"
      fontSize="0.8rem"
      {...rest}
    >
      {isExternal 
      ? (
          <Link
            href={to}
            isExternal
            _focus={{outline: 'none'}}
            onClick={onClick}
            _hover={{textDecoration: 'underline'}}
          >
            {children}
          </Link>
       ) : (
        <Link href={to} _focus={{outline: 'none'}} onClick={onClick} style={{textDecoration: 'none'}}>{children}</Link>
      )}
    </Text>
  );
}

const DesktopMenu = () => {
  const {isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1} = useDisclosure();
  // const {isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2} = useDisclosure();
  const {isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3} = useDisclosure();
  const {isOpen: isOpen4, onOpen: onOpen4, onClose: onClose4} = useDisclosure();
  return (
    <Stack spacing="1rem" direction="row" alignItems="center">
      <div onMouseOver={onOpen1} onMouseLeave={onClose1}>
        <Menu isOpen={isOpen1}>
          <SMenuButton>
            Product
          </SMenuButton>
          <MenuList minW="50px" bg="var(--components-primary-purple)" border="transparent" boxShadow="0px 10px 30px 0px rgba(187, 187, 187, 0.2)">
            <MenuItems pl="14px" pb="14px" to="https://app.slashproof.org" isExternal>
              Staking Protection
            </MenuItems>
            <MenuItems pl="14px" pb="8px">
              Restaking Protection (Coming soon!)
            </MenuItems>
          </MenuList>
          </Menu>
        </div>
        {/* <div onMouseOver={onOpen2} onMouseLeave={onClose2}>
          <Menu isOpen={isOpen2}>
            <SMenuButton>
              Developers
            </SMenuButton>
            <MenuList minW="50px" bg="var(--components-primary-purple)" border="transparent" boxShadow="0px 10px 30px 0px rgba(187, 187, 187, 0.4)">
              <MenuItems pl="14px" pt="5px" pb="8px" to="" isExternal>
                Documentation
              </MenuItems>
              <MenuItems pl="14px" pt="5px" pb="8px" to="" isExternal>
                Bug Bounty
              </MenuItems>
            </MenuList>
          </Menu>
        </div> */}
        <div onMouseOver={onOpen3} onMouseLeave={onClose3}>
          <Menu isOpen={isOpen3}>
            <SMenuButton>
              Community
            </SMenuButton>
            <MenuList minW="50px" bg="var(--components-primary-purple)" border="transparent" boxShadow="0px 10px 30px 0px rgba(187, 187, 187, 0.4)">
              <MenuItems pl="14px" pt="5px" pb="8px" to="https://twitter.com/SlashProofOrg" isExternal>
                Twitter
              </MenuItems>
              <MenuItems pl="14px" pt="5px" pb="8px" to="https://discord.gg/WjDByjYDpW" isExternal>
                Discord
              </MenuItems>
            </MenuList>
          </Menu>
        </div>
        <div onMouseOver={onOpen4} onMouseLeave={onClose4}>
          <Menu isOpen={isOpen4}>
            <SMenuButton>
              Company
            </SMenuButton>
            <MenuList minW="50px" bg="var(--components-primary-purple)" border="transparent" boxShadow="0px 10px 30px 0px rgba(187, 187, 187, 0.2)">
              <MenuItems pl="14px" pt="5px" pb="8px" to="https://medium.com/@SlashProof" isExternal>
                Blog
              </MenuItems>
              <MenuItems pl="14px" pt="5px" pb="8px" to="https://discord.gg/WjDByjYDpW" isExternal>
                Contact
              </MenuItems>
            </MenuList>
          </Menu>
        </div>
      <Button
        className="header-app-button"
        as={Link}
        px="var(--button-px)"
        py="var(--button-py)"
        borderRadius="3px"
        fontSize="var(--p-font-size)"
        textColor="var(--text-primary-white)"
        paddingTop="0.35rem"
        background="var(--components-primary-blue)"
        _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
        _focus={{ outline: 'none', boxShadow: 'none' }}
        width="8rem"
        href="https://app.slashproof.org"
        target="_new"
        fontFamily="Alien Encounters Regular"
      >
        Enter App
      </Button>
    </Stack>
  )

}

export default DesktopMenu;
