import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import GridLink from './GridLink';
import styled from 'styled-components';
import logo from '../../assets/logo.svg';

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: var(--section-bm);
`;

const Container = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
`;

const Logo = styled.img`
  width: 1.15rem;
  margin-right: 0.7rem;
`;

const Divider = styled.hr`
  border: 0.5px solid #4A5568;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

const BottomFlex = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 789px) {
    flex-direction: column;
    row-gap: 1rem;
    align-items: start;
  }
`;

const Footer = () => {
  return (
    <FlexBox>
      <Container>
        <Logo src={logo} alt="Subsea logo" />
        <Heading as="h1" size="md" textColor="#FAFAFA" fontFamily="Alien Robot" fontWeight="600">SlashProof</Heading>
      </Container>
      <GridLink />
      <Divider />
      <BottomFlex>
        <p style={{color: '#d7d7d7', fontSize: '0.75rem', fontFamily: 'Inter' }}>SlashProof &copy; {new Date().getFullYear()}. All rights reserved.</p>
      </BottomFlex>
    </FlexBox>
  );
}

export default Footer;
